const en = {
    common: {
        ok: "OK!",
        cancel: "Cancel",
        back: "Back",
        estimate: "Estimate",
        search: "Search",
        reset: "Reset",
        skip: "Skip",
        next: "Next",
        previous: "Previous",
        finish: "Finish",
        loading: "Loading...",
    },
    units: {
        day: "d",
        speed: "Speed",
        pace: "Pace",
        distance: "Distance",
        time: "Time",
        totalTime: "Total time",
        pace100: "Pace",
        "percent-pace": "% pace",
        kilometer: " km",
        meter: " m",
        mile: " mi",
        yard: " y",
    },
    segmented: {
        home: "Home",
        training: "Training",
        timeObjective: "Time objective",
        distanceObjective: "Distance objective",
    },
    toast: {
        distanceObjective: "When the race has difficulties, such as wind or elevation, the chosen pace will be adapted to allow you to finish the race.",
        timeObjective: "With this mode, the chosen pace will be respected regardless of the difficulties of the course. You will be able to perform to surpass the elements.",
    },
    tourGuide: {
        masInput: "Enter your speed or MAS",
        unit: "Change the unit of measurement (metric or imperial)",
        effortCursor: "Vary your effort (from 50% to 150%)",
        distanceInput: "Enter a custom distance to view the time based on your pace",
        timeInput: "Enter a desired time: the pace will be calculated automatically based on the distance entered",
        raceTime: "This time is based on Pacevisor algorithms using your current pace and the profile of the race. It can be refined in the race analysis screen by pressing on the event.",
        raceTimeSmall: "This time is based on Pacevisor algorithms using your current pace and the profile of the race.",
    },
    sports: {
        title: "Sports",
        run: "Run",
        bike: "Bike",
        swim: "Swim",
        transition: "Transition",
    },
    errors: {
        invalidEmail: "Invalid email address.",
    },
    errorScreen: {
        title: "Something went wrong!",
        friendlySubtitle: "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.ts`) and probably the layout as well (`app/screens/ErrorScreen`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
        reset: "RESET APP",
    },
    emptyStateComponent: {
        generic: {
            heading: "So empty... so sad",
            content: "No data found yet. Try clicking the button to refresh or reload the app.",
            button: "Let's try this again",
        },
    },
    shop: {
        title: "Shop",
        description: "The fast, durable and recyclable bottle.",
    },
    runScreen: {
        seeAll: "See all",
        favorites: "My favorites",
        removeFavoriteTitle: "Remove from favorites",
        removeFavoriteDescription: "Do you want to remove this race from your favorites?",
        nearBy: "Near me",
        categories: "Your perfect format",
        races: "Races",
        needLocationTitle: "Using your location",
        needLocationDescription: "Your location is used to find races around the location. This information is not retained after you close the app. Please stay assured that we do not store any location information outside of the device.",
        geolocationTitle: "Find races near me",
        geolocationDescription: "Click here to activate geolocation and find races near me.",
        geolocationButton: "Go",
    },
    bikeScreen: {},
    swimScreen: {},
    category: {
        allFormats: "All formats",
        "5k": "5 km",
        "10k": "10 km",
        halfMarathon: "Half-marathon",
        marathon: "Marathon",
        triathlon: "Triathlon",
        run: "Run",
        bike: "Bike",
        trail: "Trail",
        swim: "Swim",
        "seo-description": "Discover upcoming races in %{name}, offering a diverse selection of races suitable for all abilities. Full list of upcoming races in 2024 - 2025.",
    },
    settingsScreen: {
        title: "Settings",
        isMetricSystem: "Do you use the metric system?\n(e.g. kilometer, meter)",
    },
    distancesScreen: {
        title: "My distances",
        local: "My courses",
    },
    racesScreen: {
        title: "Races library",
        addRace: "Is your race there? Add it now 📝",
        cardTitle: "Want to find races?",
        cardDescription: "Pacevisor offers you analyzes to better prepare you. They are based on your pace and the profile of the race.",
        cardButton: "Find races",
        distance: {
            zero: "Distance",
            one: "Distance: %{count} km",
            other: "Distance: %{count} km",
        },
        location: "Location",
    },
    raceScreen: {
        title: "Race",
        "seo-description": "Discover the information for the %{name} race, at %{place} for %{distance} km long. It will take place on %{date}. You can download GPX file and estimate your race time based on your speed (km/h) or your pace (min/km).",
        climberNote: "Climb ability: ",
        descenderNote: "Ability to descend: ",
        abilityNoteBad: "Hard (< 1)",
        abilityNoteGood: "Easy (> 1)",
        totalDistance: `%{distance} km`,
        elevation: "%{value} m",
        elevationAvg: "avg",
        pace: "%{value} %{unit}",
        editMyInfo: "Refine my profile",
        weight: "Weight (kg)",
        seeWebsite: "Go to website",
        profile: "Race profile",
        graphSubtitle: "Hold to display a segment dynamically on the graph and on the map",
        paceTitle: "Pace",
        completeAnalysis: "See the full analysis on ",
        gpxFile: "GPX file",
        computeRace: "Compute my time",
        orDownload: "or download app",
        seeMore: "See all the roadbook",
        forecast: "Weather",
    },
    editRaceScreen: {
        title: "Edit my course",
        name: "Course name",
        placeholder: "Ex: my Sunday race",
        add: "Add a course",
        removeTitle: "Delete training",
        removeDescription: "Are you sure you want to delete this race from your phone?",
    },
    mapScreen: {
        title: "Map races",
    },
    storybook: {
        placeholder: "Placeholder",
        field: "Field",
    },
    pricing: {
        title: "Pricing",
    },
    landing: {
        "seo-title": "Calculate your time and pace in a 10 km, half-marathon run",
        "seo-description": "The application allows you to convert running times over 10 km or half-marathon distances. You can adjust distance, speed (km/h) and even pace (min/km) and everything updates automatically. An estimate of your pace is visible over other distances, and in other sports such as cycling or swimming.",
        home: "Home",
        organizer: "Organizer",
        paceTable: "Simplified table of paces",
        openInApp: "See my stats in app",
        fullTitle: "Pacevisor, your race time converter",
        download: "Download app",
        title: "Pacevisor",
        subtitle: "Goal. Conversion. Performance.",
        description: `For versatile athletes##Whether you're into running, swimming, or cycling, our app adapts to all distances, including triathlons. View your performance in miles or yards, a valuable asset for your international training.

Precision pace modulation##Adjust your pace in real time with our interactive slider. Whether for a specific training session or to estimate your times over different distances, our pace modulation feature allows you to instantly visualize the impact of each adjustment. For example, find out what 90% of 4:30 min/km over a distance of 3 km represents (that's 15 minutes!).

Dynamic time and speed adaptation##Change the time for a given distance and watch your speed adjust automatically. This feature is ideal for setting race goals or tailoring your training to specific race scenarios.

New for trail runners##Benefit from our advanced segment time calculation feature, tailored to the unique characteristics of trail running. Taking into account your personal climbing and descending abilities, this feature allows you to optimize every stage of your trail route, providing you with detailed and personalized analysis to improve your performance.`,
        racesTitle: "Find your finish line",
        tile1: "Races library",
        tile1Description: `Explore a vast collection of races.
Filter and find races across a variety of distances and elevations to train for and perform at your next competition.`,
        tile2: "Pace prediction",
        tile2Description: "Benefit from Pacevisor's advanced algorithms to predict your pace based on your past performance, race topography, and current goals.",
        tile3: "Time and distance converter",
        tile3Description: 'With the "Training" mode, easily convert time into distance and vice versa with our intuitive tool. Ideal for planning your training and managing your goals.',
        tile4: "Custom roadbook",
        tile4Description: "Get a custom roadbook for each race, detailing your race strategy, refreshment points and planned pace changes.",
        tile5: "Free and ad-free!",
        tile5Description: "Download the app for free and enjoy an ad-free experience. Only organizers pay to add their races to the library. Available on iOS and Android.",
    },
    social: {
        help: "Need help?",
        switchLang: "Version Française ?",
        privacyPolicy: "Privacy policy",
    },
    table: {
        "seo-title": "Running pace table",
        "seo-description": "Pace table to easily see the race time for the main events based on your speed (km/h and min/km) over 5 km, half-marathon or marathon.",
        info: "This simplified table allows you to visualize race times based on your speed (km/h and min/km). Download the app to get personalized estimates.",
        kmh: "Kilometers per hour",
        "min-km": "Minutes per kilometer",
        "half-marathon": "Half-marathon",
    },
    platform: {
        title: "Plateforme",
        subtitle: "Transformez l'expérience des événements avec Pacevisor",
        intro: `Intégré directement à votre plateforme d'événements, Pacevisor permet aux organisateurs d'enrichir l'expérience de chaque coureur avec des prévisions de course personnalisées.

Pacevisor se fond dans l'interface de votre plateforme pour une expérience utilisateur fluide. Offrez un service supplémentaire qui différencie votre plateforme sur le marché.`,
        addPluginTitle: "J'ajoute le plugin sur ma plateforme",
        queryParamsTitle: "L'intégration du plugin se fait par iframe, où il est possible de customiser l'interface par des paramètres d'URL à ajouter à la base url :",
    },
    new: {
        addRace: "Add a race",
        "seo-description": "Add a race to Pacevisor to integrate it into the app.",
        fields: {
            id: "Event identifier",
            active: "Active",
            title: "Title of the event",
            type: "Race type (run, bike, swim)",
            gpxUrl: "GPX file URL (optional)",
            baseSpeed: "Known speed of the participant (unit in kilometers per second, optional)",
            weight: "Weight of the participant (in kg, optional)",
            distance: "Distance of the race",
            latitude: "Latitude (optional)",
            longitude: "Longitude (optional)",
            place: "Place (optional)",
            email: "Your email",
            url: "URL of the event site, or registration (optional)",
            date: "Date of the event",
            backgroundColor: "Background color (hexadecimal, optional)",
            textColor: "Text color (hexadecimal, optional)",
            imageUrl: "Image URL (optional)",
            waypoints: "Waypoints (names and distances in meter, optional)",
            addWaypoint: "Add",
            removeWaypoint: "Remove",
            tags: "Tags (optional)",
            hideOptional: "Hide optional fields",
            showOptional: "Show more configuration",
        },
        get: "Get",
        submit: "Submit and send",
    },
    ghost: {
        title: "Ghost GPX",
        description: "Remember the ghost in Mario Kart, to see your replay? Download the GPX file of the personalized Pacevisor algorithm, import it into your smartwatch and follow the race according to your own goals.",
        step1: 'Click the "Ghost" button',
        description1: "Ghost mode is a custom GPX file for your race.",
        step2: "Select your watch application (e.g. Garmin)",
        step3: "Select the sport (e.g. running)",
        step4: "Send the activity to your watch",
        step5: "On the watch, select the sport",
        step6: "Options > Workouts",
        step7: "Race an activity",
        description7: "The course is ready to be followed! On the watch, follow the Pacevisor ghost and adapt your pace to reach your goals.",
    },
    organizer: {
        subtitle: "Improve your participants' experience with Pacevisor, the essential widget for any running, bike or swim event!",
        "seo-title": "Event organizer",
        "seo-description": "Using Pacevisor for your event is possible! Integrate the Pacevisor widget on your website to allow your participants to calculate their running time.",
        intro: "Pacevisor is an interactive tool designed to help runners visualize and plan their race strategy. Once the race has been validated by the Pacevisor team, go to the next step!",
        step: "Step %{step}: %{title}",
        seeRaceTitle: "View how it looks",
        seeRaceDescription: "Once the race has been validated by the Pacevisor team, you can view the race rendering on the application, or on the web.",
        seeRaceButton: "See race",
        shareRaceTitle: "Share on my socials",
        shareRaceDescription: "Share the race on your social networks to help your participants prepare as well as possible and strengthen your community.",
        shareRaceButton: "Copy the link by clicking here",
        addMyRaceTitle: "Add my race",
        addWidgetTitle: "Add the widget to my website",
        addWidgetDescription: "Our widget is easily integrated into your site and offers a precise estimate of running time based on the runner's pace. Copy the code below and paste it into your website source code.",
        congratulationsTitle: "Congratulations!",
        congratulationsDescription: "Your race has been successfully added. Your participants can now view and plan their race strategy. Thank you for them!",
        copyPaste: "Copy and paste the code on your site",
        exampleTitle: "Widget example",
        copied: "Copied!",
        copy: "Copy HTML code",
    },
    calendar: {
        departments: "Nearby departments",
        allRaces: "All races",
        "seo-title": "Events calendar",
        "seo-description": "Find out upcoming running, cycling or swimming events. Add your race to get an estimate of your running time. All upcoming races are there.",
        "seo-title-department": "Events calendar - %{department}",
        "seo-description-department": "Find out upcoming running, cycling or swimming events in %{department}. Add your race to get an estimate of your running time. All upcoming races are there.",
    },
    faq: {
        title: "Frequently asked questions",
        content: `How can Pacevisor improve my running performance?
Pacevisor helps you understand and optimize your running pace, allowing for more effective training and competition planning. Feel free to add custom distances with the "+" button under the "Estimation" section to tailor the app to your needs.

How do I convert min/km to km/h for running?
Use our built-in conversion tool to easily turn your pace from min/km to km/h.
For example, 6 min/km will convert to 10 km/h.

What's the best way to use Pacevisor for cycling?
Focus on the km/h conversion feature to monitor and adjust your speed during training.
For example, input 20 km/h to assess your performance on various segments.

How can Pacevisor assist me in swimming?
Use the app to convert and understand your times in min/100m, a key metric for swimmers.
In this sport, distances are always used in meters or yards.

Can I use Pacevisor for triathlons?
Absolutely, Pacevisor is ideal for managing paces in all three triathlon disciplines. Transition times will soon be added to the app.

How do I vary my training intensity with Pacevisor?
The modulation slider lets you adjust your Maximal Aerobic Speed (MAS) from 50% to 150%. It automatically recalculates your paces for different sports.
For example, if your MAS is 12 km/h and you set the slider to 80%, Pacevisor will recalculate your pace to match a MAS of 9.6 km/h. This helps you plan workouts for various intensity levels.

How do I estimate run time for a new race?
Enter your race details in Pacevisor for an accurate race time estimate based on your usual pace. A slider lets you adapt your uphill and downhill ability to refine your time, segment by segment.

How can I plan my training with Pacevisor?
Use the conversion tools to adapt your pace for different types of training, from endurance sessions to sprints.

Is it possible to adjust my pace based on altitude?
Yes, Pacevisor can help you adjust your pace for altitude variations, which are essential in trail running.

How do I integrate event data into Pacevisor?
If you're organizing or participating in an event, you can use Pacevisor to analyze and share specific race profiles.
Visit the Organizer page for more information.

What are the benefits of understanding unit conversions in sports?
Mastering these conversions helps you refine your training strategy and improve your athletic performance.`,
    },
    footer: {
        enterprise: "Enterprise",
        runner: "Runner",
    },
};
export default en;
